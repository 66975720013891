<template>
    <div class="modal-profile-item-selector edit-profile-detail items-start bg-main">
        <div class="back-button-wrapper" @click="onClickClose">
            <i class="back-button material-icons">{{ options.icon || 'chevron_left' }} </i> 
            <!-- <i class="back-button material-icons">{{ options.icon || 'chevron_left' }} </i> -->
            <span v-if="options.headerTitle" class="f-17 f-bold m-l-12 c-black">{{ options.headerTitle }}</span>
        </div>
        <ul class="result flex-fill">
            <li
                @click="onClickItem(item)"
                class="item flex-row items-center flex-between p-20"
                :class="{ selected: item.$$selected }"
                :key="item.id"
                v-for="item in options.stories"
            > 
            {{ item.name }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ModalStorySelector',
    props: ['options'],
    data: () => ({
        showSearch: true,
        buttonDisabled: false,
        selectedValue: '',
        params: null,
    }),
    watch: {
        selectedValue() {
            // if (this.options.userPersonalStory) {
            //     this.selectedValue = this.options.stories.find(o => o.id === this.options.userPersonalStory.personal_story_id)
            //     this.selectedValue.$$selected = false
            //     this.$emit('disabled', false)
            // }

            if (this.selectedValue) {
                this.selectedValue.$$selected = true
                this.$emit('disabled', false)
            } else {
                this.selectedValue.$$selected = false
                this.$emit('disabled', true)
            }
        },
    },
    mounted() {
        this.setSelectedValue()
    },
    computed: {
        essentialFunnel() {
            const fullPath = this.$route.fullPath.split('/')
            return fullPath.includes('signup')
        },
        section() {
            return this.$sections()
        },
        chooseOneMoreAllowed() {
            return false
        },
    },
    methods: {
        setSelectedValue() {
            if (this.options.userPersonalStory) {
                this.selectedValue = this.options.stories.find(o => o.id === this.options.userPersonalStory.personal_story_id)
                this.$set(this.selectedValue, '$$selected', true)
                this.$emit('disabled', false)
            }  else if (this.options.storyId) {
                this.selectedValue = this.options.stories.find(o => o.id === this.options.storyId)
                this.$set(this.selectedValue, '$$selected', true)
                this.$emit('disabled', false)
            }
        },
        async onClickItem(item) {
            if (this.options.userPersonalStory && item.id !== this.options.userPersonalStory.personal_story_id) {
                // const idx = await this.$modal.basic({
                //     body: '새로운 질문을 선택하면 작성된 답변이 초기화됩니다.<br>새로운 질문으로 바꾸시겠습니까?',
                //     buttons: [
                //         {
                //             label: 'CANCEL',
                //             class: 'btn-default',
                //         },
                //         {
                //             label: 'CONFIRM',
                //             class: 'btn-primary',
                //         },
                //     ],
                // })
                // if (idx) {
                    this.$set(item, '$$selected', !item.$$selected)
                    setTimeout(() => {
                        if (!this.chooseOneMoreAllowed) {
                            this.selectedValue = item

                            this.options.stories.forEach(o => this.$set(o, '$$selected', false))
                        }
                    }, 200)
                    this.$emit('close', item)
                // }
            } else {
                const params = await this.$modal.custom({
                    component: 'ModalStoryWriter',
                    options: {
                        profile: this.options.profile,
                        storyId: item.id,
                        headerTitle: '퍼스널 스토리 작성하기',
                        storyTitle: item.name,
                        story: this.options.userPersonalStory,
                    },
                })
                setTimeout(() => {
                    if (!this.chooseOneMoreAllowed) {
                        this.selectedValue = item

                        this.options.stories.forEach(o => this.$set(o, '$$selected', false))
                    }
                }, 200)
                // this.$emit('close', item)
                this.selectedValue = ''
                console.log(params)
                if (params.msg && params.msg === 'storyComplete') {
                    this.$emit('close')
                }
            }


            // await this.$modal.custom({
            //     component: 'ModalStoryWriter',
            //     options: {
            //         profile: this.options.profile,
            //         storyId: item.id,
            //         headerTitle: '퍼스널 스토리 작성하기',
            //         storyTitle: item.name,
            //         story: this.options.userPersonalStory,
            //     },
            // })
            // this.$emit('close')
        },
        
        onClickClose() {
            this.$emit('close')
            this.options.stories.forEach(o => this.$set(o, '$$selected', false))
        },
    },
}
</script>
<style scoped lang="scss">
.back-button-wrapper {
    position: relative;
    background: #FAF0E1;
    justify-content: center;

    i {
        position: absolute;
        width: 20px;
        height: 20px;
        left: 20px;
    }
}

ul {
    li {
        color: #7C7C7C;
        border-bottom: 0.1px solid #DCDCDC !important;
    }
}
</style>